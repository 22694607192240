<template>
	<div :class='loginStyle'>
		<div class="card login-panel p-fluid">
			<div v-if="appEnv==0"><h1 style="text-align:right; background-color: pink; color: red">DEVELOPMENT</h1></div>
			<form>
			<div class="login-panel-content">		
				<div class="p-grid">					
					<div><img style="max-width: 100%;max-height: 100%;display: block;" :src='logo' alt="roma"/></div>
					<div class="p-col-12">						
						<span class="guest-sign-in">Bienvenido, por favor utilizar la forma de ingreso para acceder al servicio</span>
					</div>
					<div class="p-col-12 username-container">
						<label>Usuario o Email</label>
						<div class="login-input">
							<InputText v-model="user.username"  type="text" name="username" autocomplete="on"/>
						</div>
					</div>
					<div class="p-col-12 password-container">
						<label>Contraseña</label>
						<div class="login-input">
							<InputText v-model="user.password" type="password"  name="password" v-on:keyup.enter="handleLogin" autocomplete="off"/>
						</div>
					</div>									
					<div class="p-col-12 p-sm-6 p-md-6">
						<Button label="Ingresar" icon="pi pi-check" @click="handleLogin"/>
					</div>					
					<div class="p-col-12 p-sm-6 p-md-6 forgetpassword-container">
						<router-link to="/recuperarcontrasena">Olvide mi contraseña</router-link>				
					</div>				
					<Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>				

					<Dialog v-model:visible="loading" :modal="true" :closable="false" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '35vw'}">					            
						<div class="flex align-content-center flex-wrap card-container">
							<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
								<ProgressSpinner style="width:50px;height:50px"/>
							</div>
							<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
							<p>Por favor, espere un momento mientras validamos sus credenciales</p>
							</div>
						</div>
					</Dialog>					
				</div>
			</div>
		</form>
		</div>
	</div>
</template>

<script>
import MdsService from '../service/mds.service';
import { CURRENT_ENV, ERR_MSG_CONEXION, CTX_TIPO_CONFIG, LOGO_LOGIN, BACKGROUND_STYLE } from '../service/constants';
import GrlService from '../service/grl.service';
import AuthService from '../service/auth.service';

import User from '../model/user';

	export default {
		data() {
			return {
				messages: [],
				logo: `assets/layout/images/${LOGO_LOGIN}`,
				loginStyle: `login-body ${BACKGROUND_STYLE}`,
				user: new User('', ''),	
				checked: false,
				appEnv: CURRENT_ENV,
				loading: false
			}
		},
		methods: {			
			next() {
				this.$store.dispatch('auth/contextosxtipo', CTX_TIPO_CONFIG).then( () => {
				
				if (!this.$store.state.auth.contextos || this.$store.state.auth.contextos.length == 0)
				{
					this.messages.push(
						{severity: 'warn', content: `El usuario no tiene afiliaciones activas, consulte con su administrador!`}								
					);
					this.$store.dispatch('auth/logout');

					this.loading = false;
				} else {

					var failOnRefresh= function(){
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');												
					};

					AuthService.startMonitorToken(failOnRefresh.bind(this));

					if(this.$store.state.auth.contextos.length > 1){
						this.$router.push('/afiliaciones')					
					} else {
						this.$store.dispatch('auth/setAppCtx', this.$store.state.auth.contextos[0]);

						GrlService.getCargarCatalogosBase(this.$store.state.auth.currentAppCtx).then(
							data => {
								this.$store.dispatch('auth/setCatalogos', data);			

								if(this.$store.state.auth.currentAppCtx.id_contexto_tipo == 2) { //Es Ctx Multidomus
									MdsService.getCatalogosBase(this.$store.state.auth.currentAppCtx).then(
										data => {
											this.$store.dispatch('auth/setMdsCatalogos', data);

											if(this.$store.state.auth.user.cambiar_passkey == 'S') {
												this.$router.push('/cambiarcontrasena');	
											} else {
												this.$router.push('/');
											}
										}
									).catch(
										err => {
											if(err.response){
												return Promise.reject(err.response.data);          
											} else {
												return Promise.reject(ERR_MSG_CONEXION);
											}        
										}
									)	
								} else {
									if(this.$store.state.auth.user.cambiar_passkey == 'S') {
										this.$router.push('/cambiarcontrasena');	
									} else {
										this.$router.push('/');
									}						
								}
							}
						).catch(
							err => {
								if(err.response){
									return Promise.reject(err.response.data);          
								} else {
									return Promise.reject(ERR_MSG_CONEXION);
								}        
							}
						);		
				}					
				}                
				})                                           
			},

			handleLogin() {
				this.loading = true;

				this.messages = [];

				if (this.user.username && this.user.password) {
				this.$store.dispatch('auth/login', this.user).then(() => {     					 				
					this.next();
				},
				error => {
					this.loading = false;
					
					var keys = Object.keys(error);
					var values = Object.values(error);

					for(var i = 0; i < keys.length; i++)
					{
						this.messages.push(
							{severity: `${keys[i]}`, content: `${values[i]}`, closable: false}		
						)
					}
				}
			);
			}
		}
		}
	}
</script>

<style scoped>
.mkt-bkg {
	background: url("/mkt-login-bg.jpg") no-repeat;	
	background-size: cover;
}

.mds-bkg{
  background: url("/mds-login-bg.jpg") no-repeat;
  background-size: cover;
}
</style>
