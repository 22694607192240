import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'dashboard',
		exact: true,
		component: () => import('./components/Dashboard.vue')
	},
	{
		path: '/afiliaciones',
		name: 'afiliaciones',
		exact: true,
		component: () => import('./pages/Afiliaciones.vue')
	},		
	{
		path: '/lstprecios',
		name: 'lstprecios',
		component: () => import('./pages/ListasPrecios.vue')
	},
	{
		path: '/invcategorias',
		name: 'invcategorias',
		component: () => import('./pages/InvCategorias.vue')
	},
	{
		path: '/invclasificaciones',
		name: 'invclasificaciones',
		component: () => import('./pages/InvClasificaciones.vue')
	},	
	{
		path: '/invsubclasificaciones',
		name: 'invsubclasificaciones',
		component: () => import('./pages/InvSubclasificaciones.vue')
	},
	{
		path: '/invmarcas',
		name: 'invmarcas',
		component: () => import('./pages/InvMarcas.vue')
	},					
	{
		path: '/productos',
		name: 'productos',
		component: () => import('./pages/Productos.vue')
	},
	{
		path: '/unidadesmedida',
		name: 'unidadesmedida',
		component: () => import('./pages/UnidadesMedida.vue')
	},
	{
		path: '/ctahabientes',
		name: 'ctahabientes',
		component: () => import('./pages/CtaHabientes.vue')
	},
	{
		path: '/ctahabiente',
		name: 'ctahabiente',
		component: () => import('./pages/CtaHabiente.vue'),
		props: true
	},
	{
		path: '/ctacategorias',
		name: 'ctacategorias',
		component: () => import('./pages/CtaCategorias.vue'),
		props: true
	},	
	{
		path: '/trx',
		name: 'trx',
		component: () => import('./pages/Trx.vue'),
		props: true	
	},	
	{
		path: '/trxs/:tipo',
		name: 'trxs',		
		component: () => import('./pages/Trxs.vue'),
		props: true
	},	
	{
		path: '/trxcntrs',
		name: '/trxcntrs',		
		component: () => import('./pages/TrxsContrato.vue'),
		props: true
	},	
	{
		path: '/pago',
		name: 'pago',
		component: () => import('./pages/Pago.vue')
	},	
	{
		path: '/abonos',
		name: 'abonos',
		component: () => import('./pages/Abonos.vue')
	},		
	{
		path: '/historialcxc',
		name: 'historialcxc',
		component: () => import('./pages/HistorialCxc.vue')
	},	
	{
		path: '/vencidoscxc',
		name: 'vencidoscxc',
		component: () => import('./pages/VencidosCxc.vue')
	},	
	{
		path: '/impcxc',
		name: 'impcxc',
		component: () => import('./pages/cxc_importacion/ImportacionAsistente.vue'),
		children: [
			{
				path: '',
				name: 'CxcImpSeleccionPerfil',
				component: () => import('./pages/cxc_importacion/SeleccionPerfil.vue'),
			},
			{
				path: '/impcxcrevision',
				name: 'CxcImpRevision',
				component: () => import('./pages/cxc_importacion/Revision.vue'),
			},	
			{
				path: '/impcxcresumen',
				name: 'CxcImpResumen',
				component: () => import('./pages/cxc_importacion/Resumen.vue'),
			},	
			],
		props: true
	},	
	{
		path: '/expcxc',
		name: 'expcxc',
		component: () => import('./pages/cxc_exportacion/exportar.vue'),
		props: true
	},		
	{
		path: '/existencia',
		name: 'existencia',
		component: () => import('./pages/Existencias.vue')
	},	
	{
		path: '/kardex',
		name: 'kardex',
		component: () => import('./pages/Kardex.vue')
	},		
	{
		path: '/usuarios',
		name: 'usuarios',
		component: () => import('./pages/Usuarios.vue')
	},
	{
		path: '/permisos',
		name: 'permisos',
		component: () => import('./pages/Permisos.vue'),
		props: true	
	},		
	{
		path: '/entidades',
		name: 'entidades',
		component: () => import('./pages/mds/Entidades.vue')
	},
	{
		path: '/entidad',
		name: 'entidad',
		component: () => import('./pages/mds/Entidad.vue'),
		props: true
	},
	{
		path: '/inmuebles',
		name: 'inmuebles',
		component: () => import('./pages/mds/Inmuebles.vue')
	},		
	{
		path: '/planservicios',
		name: 'planservicios',
		component: () => import('./pages/PlantillasMov.vue'),
		props: true
	},	
	{
		path: '/notificaciones',
		name: 'notificaciones',
		component: () => import('./pages/NotificacionConfig.vue'),
		props: true
	},		
	{
		path: '/inmueble',
		name: 'inmueble',
		component: () => import('./pages/mds/Inmueble.vue'),
		props: true
	},	
	{
		path: '/mdsestadocuentas',
		name: 'mdsestadocuentas',
		component: () => import('./pages/mds/EstadosCuenta.vue'),
		props: true
	},		
	{
		path: '/mdsemision',
		name: 'mdsemision',
		component: () => import('./pages/mds/EmisionAsistente.vue'),
		children: [
			{
				path: '',
				name: 'MdsEmisionInmuebles',
				component: () => import('./pages/mds/EmisionInmuebles.vue'),
			},
			{
				path: '/planes',
				name: 'MdsEmisionPlanServicio',
				component: () => import('./pages/mds/EmisionPlanServicio.vue'),
			},	
			{
				path: '/detalles',
				name: 'MdsEmisionDetalleCargo',
				component: () => import('./pages/mds/EmisionDetalleCargo.vue'),
			},	
			{
				path: '/revision',
				name: 'MdsEmisionRevision',
				component: () => import('./pages/mds/EmisionRevision.vue'),
			},	
			{
				path: '/resumen',
				name: 'MdsEmisionResumen',
				component: () => import('./pages/mds/EmisionResumen.vue'),
			},							
			],
		props: true
	},	
	{
		path: '/mdsreclamos',
		name: 'mdsreclamos',
		component: () => import('./pages/mds/Reclamos.vue'),
		props: true
	},		
	{
		path: '/mdsreclamo',
		name: 'mdsreclamo',
		component: () => import('./pages/mds/Reclamo.vue'),
		props: true
	},	
	{
		path: '/mdsreservas',
		name: 'mdsreservas',
		component: () => import('./pages/mds/Reservas.vue'),
		props: true
	},
	{
		path: '/mdsreservarecursos',
		name: 'mdsreservarecursos',
		component: () => import('./pages/mds/ReservaRecursos.vue'),
		props: true
	},				
	{
		path: '/mdscontadorhistorial',
		name: 'mdscontadorhistorial',
		component: () => import('./pages/mds/ContadorHistorial.vue'),
		props: true
	},
	{
		path: '/mdscontadores',
		name: 'mdscontadores',
		component: () => import('./pages/mds/Contadores.vue'),
		props: true
	},
	{
		path: '/mdscontador',
		name: 'mdscontador',
		component: () => import('./pages/mds/Contador.vue'),
		props: true
	},				
	{
		path: '/mdstarifas',
		name: 'mdstarifas',
		component: () => import('./pages/mds/ContadorTarifas.vue'),
		props: true
	},	
	{
		path: '/mdscontadorlectura',
		name: 'mdscontadorlectura',
		component: () => import('./pages/mds/RegistroLecturaContador.vue'),
		props: true
	},	
	{
		path: '/impcontlecturas',
		name: 'impcontlecturas',
		component: () => import('./pages/mds/contador_lectura_importacion/ImportacionAsistente.vue'),
		children: [
			{
				path: '',
				name: 'ContImpSeleccionPerfil',
				component: () => import('./pages/mds/contador_lectura_importacion/SeleccionPerfil.vue'),
			},
			{
				path: '/impcontrevision',
				name: 'ContImpRevision',
				component: () => import('./pages/mds/contador_lectura_importacion/Revision.vue'),
			},	
			{
				path: '/impcontresumen',
				name: 'ContImpResumen',
				component: () => import('./pages/mds/contador_lectura_importacion/Resumen.vue'),
			},	
			],
		props: true
	},					
	{
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
	{
        path: '/rpts/:id',
        name: 'rpts',
        component: () => import('./pages/Reportes.vue'),
		props: true			
	},
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/registro',
        name: 'registro',
        component: () => import('./pages/Registro.vue')
    },
    {
        path: '/activar',
        name: 'activar',
        component: () => import('./pages/Activacion.vue')
    },
    {
        path: '/recuperarcontrasena',
        name: 'recuperarcontrasena',
        component: () => import('./pages/RecuperarContrasena.vue')
    },		
    {
        path: '/cambiarcontrasena',
        name: 'cambiarcontrasena',
        component: () => import('./pages/CambioContrasena.vue')
    }				
];

const router = createRouter({
	mode: 'history',
    history: createWebHashHistory(),
    routes
});

export default router;