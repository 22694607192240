export const MKT_APP_MODE = 0;
export const MDS_APP_MODE = 1;
export const CURRENT_ENV = process.env.NODE_ENV == 'production'? 1: 0; // 0 = DESARROLLO / 1= PRODUCCION
export const CURRENT_APP_MODE = process.env.VUE_APP_MODE == "mkt" ? MKT_APP_MODE : MDS_APP_MODE; // 0 - MERKANTUS / 1 - MULTIDOMUS

export const APPNAME = CURRENT_APP_MODE == 0 ? "Merkantus" : "Multidomus";
export const API_URL_ROOT = CURRENT_ENV == 0 ? process.env.VUE_APP_URL_ROOT : "/";

export const API_APP_ROOT = 'mkt'; 
export const API_AUTH_ROOT = 'auth';

// export const API_URL_AUTH = API_URL_ROOT + '/auth';
export const API_URL_AUTH = API_URL_ROOT + API_AUTH_ROOT + '/auto/signin';
export const API_URL_AUTH_SIGNOUT = API_URL_ROOT + API_AUTH_ROOT + '/auto/signout';
export const API_URL_AUTH_REGISTRO = API_URL_ROOT + API_AUTH_ROOT + '/auto/registro';
export const API_URL_AUTH_ACTIVAR = API_URL_ROOT + API_AUTH_ROOT + '/auto/activar';
export const API_URL_AUTH_RESET_PASSWORD = API_URL_ROOT + API_AUTH_ROOT + '/auto/resetpassword';
export const API_URL_AUTH_REFRESH = API_URL_ROOT + API_AUTH_ROOT + '/auto/refreshtoken';
export const TOKEN_REFRESH_TIMEOUT = 600 * 1000; // Cada 10 minutos

export const API_URL_REGISTRO = API_URL_ROOT + API_APP_ROOT + '/registro';
export const API_URL_REGISTRO_GEN_ACTIVACION = API_URL_REGISTRO + '/generaractivacion';
export const API_URL_REGISTRO_LOGIN_X_EMAIL = API_URL_REGISTRO + '/loginxemail';
export const API_URL_REGISTRO_LOGIN_X_USUARIO = API_URL_REGISTRO + '/loginxusuario';
export const API_URL_REGISTRO_ACTUALIZA_PASSKEY = API_URL_REGISTRO + '/actualizarcontrasena';

export const API_URL_TRX = API_URL_ROOT + API_APP_ROOT + '/trx';

export const API_URL_CXC = API_URL_ROOT + API_APP_ROOT + '/cxc';
export const API_URL_CONTRATOS = API_URL_ROOT + API_APP_ROOT + '/contratos';
export const API_URL_CATALOGO = API_URL_ROOT + API_APP_ROOT + '/catalogos';

export const API_URL_MDS_CATALOGOS = API_URL_ROOT + API_APP_ROOT + '/mdscatalogos';
export const API_URL_MDS_CXC = API_URL_ROOT + API_APP_ROOT + '/mdscxc';
export const API_URL_MDS_RECLAMOS = API_URL_ROOT + API_APP_ROOT + '/mdsreclamos';
export const API_URL_MDS_CONTADORES = API_URL_ROOT + API_APP_ROOT + '/mdscontadores';
export const API_URL_MDS_RESERVAS = API_URL_ROOT + API_APP_ROOT + '/mdsreservas';


export const ERR_MSG_CONEXION = 'Hay problema en la comunicación. Por favor revise su conexión.';

export const CTX_TIPO_TODOS = 0; 
export const CTX_TIPO_MKT = 1;
export const CTX_TIPO_MDS = 2;

export const CTX_TIPO_CONFIG = CURRENT_APP_MODE == 0 ? CTX_TIPO_MKT : CTX_TIPO_MDS;

export const GLOBAL_APP_THEME = CURRENT_APP_MODE == 0 ? 'blue' : 'green';
export const GLOBAL_APP_TOPBARCOLOR = CURRENT_APP_MODE == 0 ? 'layout-topbar-blue' : 'layout-topbar-green';
export const GLOBAL_APP_COMPONENT_COLOR = CURRENT_APP_MODE == 0 ? 'blue' : 'green';

export const LOGO_LOGIN = CURRENT_APP_MODE == 0 ?'mk-logo.png': 'mds-logo.png';
export const BACKGROUND_STYLE = CURRENT_APP_MODE == 0 ?'mkt-bkg': 'mds-bkg';
